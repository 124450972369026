<template>
  <mobile-screen :header="true" screen-class="profile-screen gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_profile-help-online-photo'
        }"
      >
        <template v-slot:left>
          <router-link :to="{ name: 'r_profile' }">
            <icon icon="#cx-hea1-edit-cancel" />
          </router-link>
        </template>
        <template v-slot:center>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("menu", "account", "account-my-profile") }}
        </div>
        <template v-slot:right>
          <div
            class="clebex-edit-done-svg"
            @click="$refs.imageSelection.cropImage"
          >
            <icon icon="#cx-hea1-edit-done" />
          </div>
        </template>
      </top-header-menu-wrapper>
    </template>
    <div class="clebex-photo-selection-wrapper">
      <image-selection
        ref="imageSelection"
        :image="userPhoto ? userPhoto : ''"
        :uploadPhoto="uploadPhoto"
        :deletePhoto="deletePhoto"
      ></image-selection>
    </div>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import HomeButton from "@/components/global/HomeButton";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapGetters } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import globalMixin from "@/services/mixins/global/global";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import ImageSelection from "@/components/global/ImageSelection";

export default {
  name: "ProfilePhoto",
  data() {
    return {
      helpSlug: "profile-photo"
    };
  },
  computed: {
    ...mapState("user", ["userData"]),
    ...mapGetters("user", ["userPhoto"])
  },
  created() {},
  methods: {
    uploadPhoto(blob) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      const formData = new FormData();
      formData.append("image", blob);
      return httpServiceAuth
        .post(apiEndpoints.company.userProfileImage, formData, {
          headers: { "content-type": "multipart/form-data" }
        })
        .then(response => {
          this.$store.commit("user/setUserProfilePhoto", response.data, {
            root: true
          });
          this.$router.push({
            name: "r_profile"
          });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deletePhoto() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .delete(apiEndpoints.company.userProfileImage)
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("user/setUserProfilePhoto", null, {
            root: true
          });
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    HomeButton,
    ImageSelection
  },
  props: {
    backLinkName: {
      type: String
    }
  },
  mixins: [globalMixin, helpOnlineMixin]
};
</script>
